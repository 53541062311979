.event-edit-modal-label {
    font-size: 15px;
    font-weight: 500;
    min-width: 80px;
}

.event-edit-modal-row {
    padding: 2px 8px
}

.event-edit-modal-row-with-icon {
    padding: 8px
}

.event-edit-modal-row-item {
    padding: 2px 4px;
}

.event-edit-modal-message {
    padding: 8px
}

.event-edit-modal-label-message {
    margin-right: 8px;
}

.event-edit-modal-message-icon {
    width: 30px;
    height: 30px;
    background-color: $blue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
}

.event-edit-modal-message-list-sub {
    color: #AEB6BD;
    font-size: 11px;
}


.event-edit-modal-label-message-edit {
    width: 100%;
    border-radius: 0.25rem;
    border: 1px solid var(--vz-input-border);
}

.event-edit-modal-label-message-edit-area {
    resize: none;
    border: 0px;
}

.event-edit-modal-label-message-edit-area::-webkit-scrollbar {
    width: 12px;
}

.event-edit-modal-label-message-edit-area::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.event-edit-modal-label-message-edit-area::-webkit-scrollbar-thumb {
    background-color: $offText;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

.event-edit-modal-label-message-edit-btn {
    padding-left: 3px;
    padding-right: 3px;
    min-width: 60px;
}

.event-edit-modal-message-btn {
    width: 60px;
    margin-bottom: 8px;
}

.event-edit-modal-btn {
    margin-left: 8px;
    margin-right: 8px;
}

.event-edit-modal-message-list-container {
    max-height: 100px;
    overflow: auto;
    padding: 0;
    margin-right: 8px;
}

.event-edit-modal-message-list-container::-webkit-scrollbar {
    width: 12px;
}

.event-edit-modal-message-list-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.event-edit-modal-message-list-container::-webkit-scrollbar-thumb {
    background-color: $offText;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}