#page-developer {
    .page-developer-device-details {

        .input-group {
            max-width: 800px;
            margin: 6px 0px;
        }
    }

    .error-msg {
        margin-left: 10px
    }

    .developer-tab-container {
        background-color: white;
        width: 100%;
        height: calc(100% - $footer-height);

        .tab-pane {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;
        }

        .nav-link {
            font-weight: 500;
        }
    }

    .device-table {
        margin-top: 0px;
        margin-bottom: 10px;
        max-height: calc(100vh - $header-height - $footer-height - 200px);
        /* Set the desired height for the table container */
        overflow-y: auto;
        /* Enable vertical scrolling */

        th {
            position: sticky;
            top: 0;
            text-align: center;
        }

        .collapse-icon {
            width: 40px;
            text-align: center;
            margin-bottom: -5px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            background-color: white;
        }

        td {
            vertical-align: middle;
            text-align: center;
        }

        table> :not(caption)>*>* {
            padding: 0.55rem 0.6rem;
        }
    }

    .device-table::-webkit-scrollbar {
        width: 12px;
    }

    .device-table::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    .device-table::-webkit-scrollbar-thumb {
        background-color: $offText;
        border-radius: 6px;
        border: 3px solid #f1f1f1;
    }

}