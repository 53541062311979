//
// _dashboard.scss
//
.dash-section {
  padding-left: 6px;
  padding-right: 6px;
}


.dash {
  display: flex;
  flex-flow: column;
  height: calc(100% - #{$footer-height});
  position: relative;

  .col_2_device {
    max-height: 50%;
    position: relative;
    min-height: 250px;

    .dash-section {
      position: relative;
    }
  }

  .col_2_event {
    flex: 1;
    margin-bottom: 0px;
    min-height: 50%;
    position: relative;

    .dash-event-card-text {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      justify-content: center;
    }

    .dash-event-card-text-1 {
      padding: 0.1rem 0rem 0rem 1rem;
    }
  }

  .col_3_device {
    height: 100%;
    position: relative;

    .dash-event-card-text {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
    }

    .dash-event-card-text-1 {
      padding: 0.1rem 0rem 0rem 0.3rem;
    }
  }

  .dash-section {
    position: relative;

    .section-card {
      height: 100%;
      position: relative;

      .section-cardbody {
        height: 100%;
        position: relative;

        .section-container {
          height: 100%;
          position: relative;

          .card-container {
            overflow-y: auto;
          }

          .card-container::-webkit-scrollbar {
            width: 12px;
          }

          .card-container::-webkit-scrollbar-track {
            background-color: #f1f1f1;
          }

          .card-container::-webkit-scrollbar-thumb {
            background-color: $offText;
            border-radius: 6px;
            border: 3px solid #f1f1f1;
          }
        }
      }
    }
  }
}

.dash-btn-error-modal {
  min-width: 140px;
}

.dash-device-title {
  font-weight: 500;
  font-size: 1.2rem;
  color: $dark;
}

.dash-device-subtile {
  color: $lightDark;
  font-size: 0.9rem;
}

.dash-device-header-icon {
  font-size: 2em;
  color: $lightDark;
  padding-left: 8px;
}

.dash-sort-icon {
  margin-top: -8px;
  margin-right: -8px;
}

.dash-sort-title {
  font-size: 1.1em;
  margin-bottom: 8px;
  font-weight: 500;
}

.dash-sort-hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: #adb5bd;
}

.dash-filter-container {
  margin-bottom: 10px;
}

.dash-filter-icon {
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 6px;
}

.dash-edit-modal-header {
  border-bottom: thin;
  border-bottom-color: $light;
  border-bottom-style: solid;
  padding-bottom: 12px !important;
}

.dash-edit-modal-title-icon {
  font-size: 28px;
  margin-right: 6px;
}

.dash-edit-modal-title {
  font-size: 20px;
  margin-bottom: 2px;
}

.dash-edit-modal-status-icon {
  font-size: 32px;
}

.dash-edit-modal-right-container {
  margin-left: 12px;
}

.dash-edit-modal-status-text {
  font-size: 15px;
  margin-left: 12px;
  margin-right: 12px;
}

.dash-edit-modal-section-title {
  margin-left: 12px;
  font-size: 14px;
  margin-top: 8px;
  font-weight: 400;
}

.dash-edit-modal-section-container {
  background-color: $boxBackground;
  border-radius: 5px;
  padding: 8px 12px 12px;
}

.dash-edit-modal-input {
  width: 150px;
}

.dash-edit-modal-input-label {
  margin-bottom: 3px
}

.dash-edit-modal-timepicker-label {
  color: $lightDark;
  font-size: 11px;
}

.dash-edit-modal-timepicker {
  width: 150px
}

.dash-event-card {
  margin-top: 4px;
  margin-bottom: 4px;
}

.dash-event-container {
  color: $lightDark;
  padding: 0.65em;
}

.dash-event-eventType {
  padding: 2px 6px;
  color: white;
  width: 100px;
  text-align: center;
  font-size: 0.925rem;
  border-radius: 4px;
}

.dash-event-card-status-icon {
  display: flex;
  align-items: center;
  padding: 4px 2px 4px 6px;
  width: 5%;
  min-width: 40px;
}

.dash-event-card-text-highlight {
  font-weight: 500;
  color: $dark;
}

.dash-event-handle-status {
  color: white;
  text-align: center;
  font-size: 1rem;
  border-radius: 4px;
  padding: 2px 14px;
}

.dash-event-btn {
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  background-color: #F0F0F0;
  color: $primary;
  border-width: 1px;
  border-color: $primary;
  border-style: solid;
}

.dash-event-btn:disabled {
  background-color: $primary;
  color: $white;
}

.dash-event-card-second-line {
  justify-content: space-between;
}

.dash-device-card {
  width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding: 0px 4px;

  .btn-check:checked+.btn,
  :not(.btn-check)+.btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    background-color: transparent;
  }
}

.dash-device-cardbody {
  color: $lightDark;
  padding: 0px 6px;
}

.dash-device-container {
  width: 100%;
}

.dash-device-card-icon {
  width: 6%;
  max-width: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dash-device-card-text {
  width: 11%;
  color: #666666;
  font-size: 1.2em;
}

.dash-device-card-text-off {
  color: $offText;
}

.dash-device-card-text-toilet-using {
  color: $warning;
}

.dash-device-card-text-toilet-idle {
  color: $eventMonitorIdle;
}

.dash-device-card-text-toilet-warn {
  color: $warning;
}

.dash-device-card-text-toilet-danger {
  color: $eventAlarmOn;
}

.dash-device-card-text-nosignal {
  color: $darkBlue;
}

.dash-device-card-text-toilet-handling {
  color: $eventMonitorIdle;
}

.dash-device-card-text-grid-card-header {
  height: 42px;
  background-color: $primary;
  padding-top: 0px 17px;

  .dash-device-card-text-grid-card-header-title {
    color: white;
    font-size: 1.3rem;
  }

  ;
  display: flex;
  align-items: center;
}

.dash-device-card-text-grid {
  margin: 4px 4px;
  flex: 1;
}

.dash-device-card-text-grid-label {
  color: white;
  text-align: center;
  line-height: 38px;
  border-radius: 4px;
  font-size: 1.15rem;
}

.dash-device-card-text-connection-icon {
  padding: 0px 4px;
  margin-top: -4px;
  margin-left: 4px;
}

.dash-device-card-text-connection-icon:hover {
  color: white;
  background-color: white;
  border-color: white;
}

.dash-history-edit-btn {
  display: flex;
  flex-direction: row;
}

.dash-history-collapse-btn {
  height: 50px;
  width: 30px;
}

.mq-connection-icon {
  color: $orange;
  margin-left: 4px;
  font-size: 28px;
  margin-bottom: 4px;
}

.socket-connection-icon {
  color: $red;
  margin-left: 4px;
  font-size: 28px;
  margin-bottom: 4px;
}

.scaled-svg {
  transform-origin: center;
  transform: scale(var(--scale, 1));
}

.dash-tooltip-button,
.dash-tooltip-button:hover {
  background-color: transparent;
  border: 0;
}

.dash-alarm-container {
  padding: 10px 6px;
}

.dash-danger-status-box {
  background-color: $eventAlarmOn;
  color: white;
  border-color: $eventAlarmOn;
  animation: changeColor 2s infinite;
  /* 1s duration, infinite loop */
  width: 130px;
  text-align: center;
  border-radius: 4px;
  border-width: 1px;
  border-color: $eventAlarmOn;
  border-style: solid;
}

.dash-danger-status-box-text {
  padding: 4px;
}

@keyframes changeColor {
  0% {
    background-color: $eventAlarmOn;
    color: white
  }

  100% {
    background-color: white;
    color: $eventAlarmOn;
  }
}

.dash-device-card-grid {
  .dash-event-btn {
    width: 100%
  }
}

.dash-event-confirm-modal-btn {
  font-size: 18px;
  margin-left: 8px;
  margin-right: 8px;
  min-width: 100px;
}

.dash-event-confirm-modal-btn-error {
  font-size: 1rem;
  margin-top: 10px;
  color: red;
}

.dash-device-show-history-btn {
  height: 38px;
  font-size: 1rem;
  margin-top: 2px;
  margin-left: 4px;
}

@import 'eventEditModal';